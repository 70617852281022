/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Helmet from 'react-helmet'
import Image from "gatsby-image"
import "./layout.css"
import { FaCalendarAlt, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'
import BackgroundImage from 'gatsby-background-image'
import $ from "jquery"
import ConversionLoggingInit from '../util/conversionLogging.js';
import Footer from "./footer"
import CTA from "../components/cta"

ConversionLoggingInit.init({env: 'prod'});


const Layout = ({ children }) => {
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery($slug: String) {
      site {
        siteMetadata {
          title
        }
      }
      sanityCompanyInfo {
        companyname
        companyTagline
        phone
        licenses
        logo{
          asset{
            fluid{
              ...GatsbySanityImageFluid
              src
            }
          }
        }
        favicon{
          asset{
            fluid{
              ...GatsbySanityImageFluid
              src
            }
          }
        }
        ctalogo {
          asset {
            fluid{
              ...GatsbySanityImageFluid
              src
            }
          }
        }
        primarycolor{
          hex
        }
        secondarycolor{
            hex
        }
        accentcolor{
            hex
        }
        tertiarycolor{
          hex
        }
        tertiaryaltcolor{
          hex
        }
        analytics
        marchex
        clicky
        remarketing
      }
      allSanityBadges{
        edges {
          node {
            badge_img {
              asset {
                fluid {
                  src
                }
              }
            }
            badge_name
          }
        }
      }
      sanityPages(slug: {current: {eq: $slug}}) {
        pagetitle
        slug{
          current
        }
        coupon{
          title
          type
        }
        serviceimage{
            asset{
                fluid(maxWidth: 1920){
                    ...GatsbySanityImageFluid
                    src
                }
            }
        }
      }
    }
  `)


  function changeActive(){
    $(".form").toggleClass("expanded");
    $('body').toggleClass('formExpanded');
  }  

  function mobileActive(){
    $(".mobile_navi").toggleClass("expanded");
    $('body').toggleClass('mobileExpanded');
  }  

  
/* REPLACE COMPANYNAME IN COPY */





  return (
    <>
    
    <Helmet>
          <link rel="icon"
          type="image/png"
          href={data.sanityCompanyInfo.favicon.asset.fluid.src} defer="false" />
          <script data-api-key="ckgb6628g00ld06moclq6whc8" data-defer="true" id="se-widget-embed" src="https://embed.scheduleengine.net/schedule-engine-v3.js"></script>
         <script src="https://code.jquery.com/jquery-3.3.1.min.js" integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8=" crossOrigin="anonymous" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="twitter:card" content="summary" />

        <meta property="og:title" content={data.sanityCompanyInfo.companyname + " | " + data.sanityPages.pagetitle} />
      

        {data.sanityCompanyInfo.analytics ? (
          <script async className="AnalyticsCode" src={`https://www.googletagmanager.com/gtag/js?id=${data.sanityCompanyInfo.analytics}`}/> 
          
          ) : null}

          {data.sanityCompanyInfo.analytics ? (
              <script>
                {`window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                        
                  gtag('config', '${data.sanityCompanyInfo.analytics}');
                `}
              </script>
            ) : null}

          {data.sanityCompanyInfo.remarketing ? (
          <script async className="RemarketingCode" src={`https://www.googletagmanager.com/gtag/js?id=${data.sanityCompanyInfo.remarketing}`}/> ) : null}

          {data.sanityCompanyInfo.remarketing ? (
            <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${data.sanityCompanyInfo.remarketing}');
            `}
          </script>

          ) : null}
          
          {data.sanityCompanyInfo.remarketing ? (
              <script>{`
              gtag('event', 'page_view', {
                'send_to': '${data.sanityCompanyInfo.remarketing}',
                'user_id': 'replace with value'
              });
              `}
              </script>
          ) : null}


          <script>
            {`
              /***** ADD CITY TO URLS IN PAGE *****/
              function addCity(){
                if(typeof window !== 'undefined'){
                    $('div.pageContent a').attr('href', function(i, href){
                        city= getUrlVars()['city'];
                        return href + "?city=" +  city; 
                    });
                }  
              }
            `}
          </script>

          <script>{`
            /* SINGLE COUPON PRINT */
            $(".couponsRow .coupon").each(function(){
              $(this).click(function(){
                  if(typeof window !== 'undefined'){
                      var printContents = $(this).wrap('<p/>').parent().html();
                      var originalContents = document.body.innerHTML;
                      document.body.innerHTML = "${data.sanityCompanyInfo.logo.asset.fluid.src}";
                      document.body.innerHTML = printContents;
            
                      window.print();
                      document.body.innerHTML = originalContents;
                  }

              });
            });
          `}</script>
          <meta name="theme-color" content={data.sanityCompanyInfo.secondarycolor.hex} />
          <script type="text/javascript">
                {`var SETUP_VS_LP = function(){
                    INIT_VS_LP({
                        env: 'prod'
                    });
                };`}
					    </script>
					    <script src="https://s3.amazonaws.com/vs.static-files/vs_lp_conv_bundle.js"  async defer onLoad={`SETUP_VS_LP`}></script>
              
              <script type="text/javascript" src="https://js.web-2-tel.com/org-sdk?identifier=f4a4eee430c24f86a693832003d5c83c" async></script>
              
              <script type="text/javascript">{`
                vs_account_id      = "${data.sanityCompanyInfo.marchex}";
              `}</script>

              <script>{`var clicky_site_ids = clicky_site_ids || []; clicky_site_ids.push(${data.sanityCompanyInfo.clicky});`}</script>
              <script async src="//static.getclicky.com/js"></script>
              <script src="https://kit.fontawesome.com/4ab4233178.js" crossorigin="anonymous"></script>
              <script type='text/javascript'>{`var script = document.createElement('script');
              script.async = true; script.type = 'text/javascript';
              var target = 'https://www.clickcease.com/monitor/stat.js';
              script.src = target;var elem = document.head;elem.appendChild(script);`}
              </script>
   
    </Helmet>
    <noscript>
      <a href='https://www.clickcease.com' rel='nofollow'><img src='https://monitor.clickcease.com/stats/stats.aspx' alt='ClickCease'/></a>
   </noscript>

    <div className="pagewrapper">
    <Header />    



          <div>
            <main>{children}</main>
            <div className="scheduleMobile" >
                <div className="innerSchedule" onClick={changeActive} style={{backgroundColor: data.sanityCompanyInfo.secondarycolor.hex}}>
                  <FaCalendarAlt /> <span>Schedule</span>
                </div>
                <a href={'tel:' + data.sanityCompanyInfo.phone } className="innerPhone" style={{backgroundColor: data.sanityCompanyInfo.tertiarycolor.hex}}>
                  <FaPhoneAlt /> <span>Call now</span>
                </a>
            </div>



            <footer className="footer" style={{ backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
              <div className="badgeBanner">
                  <div className="columns">
                    <div className="badges" style={{ backgroundColor: data.sanityCompanyInfo.tertiaryaltcolor.hex}}>
                      {data.allSanityBadges.edges.map(({ node: badge }) => (
                                <Image fluid={badge.badge_img.asset.fluid} key={badge.badge_name}/>
                            ))}
                    </div>
                  </div>
                </div>
              <div className="container">
                <div className="licenses">
                    {data.sanityCompanyInfo.licenses.map(( license  => 
                      <div>{license}</div>
                    ))}
                </div>
              </div>
              <div className="containerFooter">
               <div className="footerInner">
                 <div>
                  <Image location=""
                    fluid={data.sanityCompanyInfo.logo.asset.fluid}
                    style={{ height: "auto", width: "200px" }}
                    className="align-center"
                    alt="Logo"
                  />
                 </div>
                 <div className="footerInfo">
                   <p style={{ color: data.sanityCompanyInfo.accentcolor.hex }}>Contact Us Today!</p>
                   <a className="headerbtn phone" style={{ color: data.sanityCompanyInfo.accentcolor.hex }} href={"tel:" + data.sanityCompanyInfo.phone}><FaPhoneAlt /> {data.sanityCompanyInfo.phone}</a>
                 </div>
               </div>
                <p>&copy; {data.sanityCompanyInfo.companyname} | Marketing by <a href="http://vitalstorm.com/" target="_blank" rel="noopener noreferrer">VitalStorm</a> | <a href="/privacy-policy/">Privacy Policy</a></p> 
              </div>        
            </footer>
          </div>
        </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Layout
